<template>
  <b-card class="card-custom rounded-top-0" body-class="pt-15">
    <template v-slot:footer>
      <b-container class="max-w-900px text-right">
        <b-btn v-cs-loading="submiting" variant="primary" class="px-5" @click="onSaveData()">
          Save
        </b-btn>
      </b-container>
    </template>
    <b-container class="max-w-900px px-0">
      <!--begin::Form Group-->
      <b-form-group>
        <template v-slot:label>
          Bank Name
          <span class="text-danger">*</span>
        </template>
        <b-form-input
          type="text"
          class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          v-model="form.bank_name"
          :state="validateState($v.form.bank_name)"
        />
      </b-form-group>
      <!--end::Form Group-->

      <h3 class="text-dark text-center my-12">Address</h3>

      <!--begin::Form Group-->
      <b-row>
        <b-col lg="8">
          <b-form-group>
            <template v-slot:label>
              Street Address
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="form.bank_street_address"
              :state="validateState($v.form.bank_street_address)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group>
            <template v-slot:label>
              Apt/Suite
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="form.bank_suite"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4">
          <b-form-group>
            <template v-slot:label>
              City
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="form.bank_city"
              @keypress="onlyLetter($event)"
              :state="validateState($v.form.bank_city)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group>
            <template v-slot:label>
              State
              <span class="text-danger">*</span>
            </template>
            <b-form-select
              :options="stateOptions"
              class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="form.bank_state"
              :state="validateState($v.form.bank_state)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group>
            <template v-slot:label>
              Zip
              <span class="text-danger">*</span>
            </template>

            <b-form-input
              v-mask="defaultMask.zip"
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="form.bank_zip"
              :state="validateState($v.form.bank_zip)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!--end::Form Group-->

      <h3 class="text-dark text-center my-12">Bank Account Details</h3>

      <!--begin::Form Group-->
      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Account number (8-12 digits)
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-mask="defaultMask.bankAccountNumber"
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="form.account_number"
              :state="validateState($v.form.account_number)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Routing number (9 digits)
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="form.routing_number"
              :state="validateState($v.form.routing_number)"
              v-mask="defaultMask.routingNumber"
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!--end::Form Group-->
    </b-container>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import { required, minLength, maxLength, integer } from 'vuelidate/lib/validators';

export default {
  name: 'profile-personal-information',
  validations: {
    form: {
      bank_name: { required },
      bank_street_address: { required },
      bank_city: { required },
      bank_state: { required },
      bank_zip: { required, integer, minLength: minLength(5), maxLength: maxLength(5) },
      routing_number: { required, integer, minLength: minLength(9), maxLength: maxLength(9) },
      account_number: { required, integer, minLength: minLength(8), maxLength: maxLength(12) },
    },
  },
  data() {
    return {
      submiting: false,
      form: {},
    };
  },
  computed: {
    userData() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    const name = [this.userData.first_name || '', this.userData.last_name || ''].filter((e) => e != '');
    this.$root.$emit('changeTitle', name.join(' ') || this.userData.email);
    this.initForm(this.userData);
  },
  methods: {
    ...mapActions({
      updateProfile: 'auth/updateProfile',
    }),
    initForm(data = {}) {
      this.form = {
        bank_name: data.bank_name || '',
        bank_street_address: data.bank_street_address || '',
        bank_city: data.bank_city || '',
        bank_state: data.bank_state || '',
        bank_suite: data.bank_suite || '',
        bank_zip: data.bank_zip || '',
        account_number: data.account_number || '',
        routing_number: data.routing_number || '',
      };
    },
    onSaveData() {
      if (!this.$v.form.$invalid) {
        this.submiting = true;
        this.updateProfile(this.form).finally(() => (this.submiting = false));
      } else {
        this.$v.$touch();
      }
    },
  },
};
</script>
